/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://f79ce2c4ae663522265504d62d620354@o4505447362068480.ingest.us.sentry.io/4508002060206080',

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,

	// Optional: Initialize Session Replay:
	integrations: [Sentry.replayIntegration()],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0
});

export const handleError = Sentry.handleErrorWithSentry();

// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
